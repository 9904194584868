<!-- 知识大讲堂 -->
<template>
  <div>
    <!-- tab栏 -->
    <van-tabs v-model="tabactive" color="#188EFB">
      <van-tab title="企业规划"
        ><div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in list" :key="item.index">
            <router-link to="/huayang/particulars">
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3" style="color:#000">{{ item.Title }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div></van-tab
      >
      <van-tab title="法律法规"
        ><div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in listtwo" :key="item.index">
            <router-link to="/huayang/particulars">
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3" style="color:#000">{{ item.Title }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div></van-tab
      >
      <van-tab title="财税知识"
        ><div class="information">
          <!-- 全部 -->
          <div class="center" v-for="item in listthree" :key="item.index">
            <router-link to="/huayang/particulars">
              <div class="Content">
                <div style="display: inline-block" class="ContentA">
                  <img :src="item.AImgUrl" alt="" />
                </div>
                <div style="display: inline-block" class="ContentB">
                  <span class="van-multi-ellipsis--l3" style="color:#000">{{ item.Title }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <!-- 没有更多数据了 -->
          <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
        </div></van-tab
      >
    </van-tabs>
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识大讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >企业信息</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import { WeGetActivityPage } from "@/api/RealInfo";
export default {
  data() {
    return {
      active: 0,
      tabactive: 0,
      akId: 0, //tab栏
      //资讯列表
      list: [
        {
          Title: "中小企业划型标准规定 中小企业贷款融资知识大全",
          Outline:
            "银行贷款是企业最常用的一条融资渠道，企业贷款是指企业为了生产经营的银行或其他金融机构按照规定利率和期限的一种借款方式。银行贷款融资的额度比较高，同时利率也比较低，所以可以用银行贷款的方式来进行固建、技术改造等大额长期投资。",
          ADate: "2021-8-17 09:08",
          AImgUrl:
            "https://tkdlapi.zhsq.cloud//upload/images/2021/8/21a12e80-8b4.jpg",
        },
        {
          Title: "中小企业划型标准规定 中小微企业如何划分",
          Outline:
            "大中小企业划分标准是什么？小微企业划分标准是什么？中小企业划型标准有哪些具体的规定？大中小微企业划分标准是划分大中小微企业的标准，是为贯彻落实工业和信息化部、国家统计局、国家发展改革委、财政部制定了《关于印发中小企业划型标准规定的通知》，用来规范中小企业。下面，就来看看中小微企业划分标准是什么。",
          ADate: "2021-8-17 09:20",
          AImgUrl:
            "https://tkdlapi.zhsq.cloud//upload/images/2021/8/eda4c02f-6de.jpg",
        },
      ],
      listtwo:[
        {
          Title: "个体工商户年检流程 个体工商户网上年检方法",
          Outline:
            "个体工商户网上年检方法是什么呢？个体工商户，每年都需要向工商局办理《营业执照》年检，逾期末办理年检的，将被纳入经营异常名录，其信用记录将受严重影响。年检也称为年报，个体工商户营业执照年检从2014年3月1日起取消线下申报，改为网上申报。接下来就为大家详细介绍个体工商户年检流程",
          ADate: "2021-8-17 09:16",
          AImgUrl:
            "https://tkdlapi.zhsq.cloud//upload/images/2021/8/8bd837bc-51a.jpg",
        },
      ],
      listthree:[
        {
          Title: "公司财务怎么做账 企业财务做账的误区有哪些",
          Outline:
            "做账是指财务人员进行账务处理的过程，那么公司财务怎么做账呢？首先要根据企业情况确定会计科目表和报表格式，然后再根据经常发生的科目设置各类账簿，通过原始凭证做记账凭证，制作出财务报表。实际操作中，财务做账常常会陷入没有发票不会做帐、收据不能入账、把税法当成做账准则等误区。做账要注意保存好原始单据凭证，核对金额，小心细致。下面来了解一下财务做账的知识吧！",
          ADate: "2021-8-17 09:12",
          AImgUrl:
            "https://tkdlapi.zhsq.cloud//upload/images/2021/8/988fe689-9d8.jpg",
        },
      ],
      listfrom: {}, //查询传参
      hidden: true, //没有更多数据了
    };
  },
  methods: {
    // 获取分页列表
    getList() {
      WeGetActivityPage({ akId: 0 })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.list = {};
            } else {
              this.list = res.data.data;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].AImgUrl == "") {
                  this.list[i].AImgUrl =
                    "https://cuiyunapi.dexian.ren//upload/images/2021/7/873b94ff-81d.png";
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    // tan点击切换事件
    onClick(e) {
      this.akId = e;
      // this.getList();
    },
  },
  mounted() {
    if (this.$route.query["openid"]) {
      setOpenId(this.$route.query["openid"]);
    }
    // this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
</style>
